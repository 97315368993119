import styled from 'styled-components'
import { Link } from 'gatsby'

export const IconLink = styled(Link)`
  margin-right: 10px;

  @media (min-width: 768px) {
    margin: 0 40px 0 0;
  }
  &:last-child {
    margin: 0;
  }
`

export const Container = styled.div`
  display: flex;
  border-bottom: 1px solid #889390;
  padding-bottom: 20px;
  margin-bottom: 35px;
  max-width: ${props => (props.operators ? '100%' : '768px')};

  @media (min-width: 768px) {
    margin: 0 auto;
    margin-bottom: 35px;
    font-size: 14px;
    padding-bottom: 30px;
  }

  .region {
    margin-top: 13px;
    padding-left: 12px;
    border-left: ${props => (props.hasCat ? '1px solid #e8e0d3' : 'none')};

    height: 20px;
    @media (min-width: 768px) {
      margin-top: 11px;
      margin-left: ${props => (props.hasCat ? '-24px' : '-12px')};
      padding-left: 15px;
    }
  }
`

export const IconWrapper = styled.div`
  display: flex;
  margin: 0 9px 0 9px;
  align-items: flex-end;

  a {
    display: block;
  }

  @media (min-width: 768px) {
    font-size: 14px;
    margin: 0 40px 0 0;

    &:last-child {
      margin: 0;
    }
  }
  svg {
    position: relative;
    top: 4px;
    width: 20px;
    stroke: #a55331;
    g {
      stroke: #a55331;
    }
  }
  p {
    padding-left: 12px;
    font-size: 12px;
    margin: 0;
    color: #212c1f;
    font-weight: 400;

    @media (min-width: 768px) {
      font-size: 14px;
    }
  }
`
