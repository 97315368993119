import React, { Component } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Layout from '../../components/layout'
import { RelatedArticles } from '../../components/RelatedArticles'
import { Categories } from '../../components/Categories'

import { ContactInformation } from '../../components/ContactInformation'
import SEO from '../../components/seo'

import { parseBlockContent } from '../../components/Blocks/data'

import {
  ComponentContainer,
  SidebarPageContentStyles,
  ContentStyles,
  SidebarPageWrapper,
  SubText,
} from '../../utils/globalStyledComponents'
import { InThisSection } from '../../components/InThisSection'

class PageTemplate extends Component {
  render() {
    let post

    post = this.props.data.page

    const {
      phone,
      email,
      website,
      days,
      address,
      suburb,
      postcode,
      state,
    } = post.avContactInfo

    const { uri, ancestors, templateSlug } = post

    const sidebar = templateSlug === 'internalsidebar'
    const Content = post.content ? parseBlockContent(post.content, sidebar) : ''
    const addressString = `${address} ${suburb}, ${state} ${postcode}`

    return (
      <Layout
        page
        categories={post.categories}
        generic
        mobHeader={post.templateSlug === 'madeofballarat' ? true : false}
        headerImage={post.avContent.featureImage}
        headerTitle={post.title}
        breadcrumb={{ name: post.title }}
        footerScripts={post.avFooterScripts?.ftScripts}
      >
        <SEO
          title={post.seo.title}
          image={post.avContent.featureImage?.sourceUrl}
          description={post.seo.metaDesc}
        />

        <ComponentContainer>
          {post.avIntro.introHeading || post.avIntro.introDescription ? (
            <SubText>
              <h4>{post.avIntro.introHeading}</h4>
              <p>{post.avIntro.introDescription}</p>
            </SubText>
          ) : (
            <Categories categories={post.categories?.nodes} />
          )}

          {sidebar ? (
            <SidebarPageWrapper>
              <div>
                <SidebarPageContentStyles>{Content}</SidebarPageContentStyles>
                {address && address.length && (
                  <ContactInformation
                    generic
                    address={addressString}
                    openingHours={days}
                    phone={phone}
                    email={email}
                    website={website}
                  />
                )}
              </div>
              <div>
                <InThisSection
                  ancestors={ancestors?.nodes}
                  currentPageLink={uri}
                />
              </div>
            </SidebarPageWrapper>
          ) : (
            <>
              <ContentStyles>{Content}</ContentStyles>
              {address && address.length && (
                <ContactInformation
                  generic
                  address={addressString}
                  openingHours={days}
                  phone={phone}
                  email={email}
                  website={website}
                />
              )}
            </>
          )}
        </ComponentContainer>

        <RelatedArticles
          heading={post.avRelatedPages.relatedHeading}
          relatedPages={post.avRelatedPages.relatedPages}
        />
      </Layout>
    )
  }
}

PageTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export default PageTemplate

export const pageQuery = graphql`
  query($id: String!) {
    page: wpPage(id: { eq: $id }) {
      ancestors {
        nodes {
          ... on WpPage {
            title
            uri
            depth
            menuOrder

            wpChildren {
              nodes {
                ... on WpPage {
                  title
                  uri
                  menuOrder
                }
              }
            }
          }
        }
      }

      title
      uri
      templateSlug
      seo {
        title
        metaDesc
      }
      avFooterScripts {
        ftScripts
      }
      content
      categories {
        nodes {
          name
          slug
        }
      }
      avContent {
        featureImage {
          sourceUrl
          srcSet
        }
      }
      avIntro {
        introDescription
        introHeading
      }
      avContactInfo {
        venue
        address
        state
        postcode
        suburb
        phone
        email
        website
        days {
          day
          times
        }
      }

      avRelatedPages {
        relatedHeading
        relatedPages {
          ... on WpPage {
            title
            uri
            excerpt
            categories {
              nodes {
                slug
                name
              }
            }
            avContent {
              featureImage {
                sourceUrl
                srcSet
              }
            }
          }
          ... on WpItinerary {
            title
            uri
            excerpt

            avItinerary {
              summary {
                days
              }
            }
            avContent {
              featureImage {
                sourceUrl
                srcSet
              }
            }
          }
        }
      }
    }
  }
`

// acf {
// 	intro_heading
// 	intro_description
// 	...contactDetails
// 	...featureImagePage
// 	...relatedPages
// }
