import styled from 'styled-components'
import { device } from '../../utils/breakpoints'

export const Header = styled.h3`
  font-size: 22px;
  letter-spacing: 0.03em;
  text-transform: uppercase;

  padding-bottom: 24px;
  border-bottom: 1px solid black;
  margin: 0;

  @media ${device.desktop} {
    font-size: 28px;
  }
`

export const LinkArrowContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;

  align-items: center;
  border-bottom: 1px solid #b9bab4;

  height: 70px;
`

export const IconWrapper = styled.div`
  display: flex;
  position: relative;
  width: 50px;

  height: 100%;
  justify-content: end;
  align-items: center;
  cursor: pointer;

  svg {
    width: 12px;
    height: 14px;
    transform: ${props => (props.isOpen ? 'rotate(-90deg)' : 'rotate(90deg)')};
    transition: 200ms;
  }

  ::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: red; */
  }
`

export const MainMenuTitle = styled.div`
  font-weight: 900;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: ${props => (props.isOpen ? '#A55331' : '#212C1F')};
  white-space: nowrap;
  margin: 0;
`

export const NavList = styled.ul`
  list-style: none;
  display: ${props => (props.expanded ? 'block' : 'none')};
  padding-top: 10px;

  margin: 0;
`

export const Title = styled.span`
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -0.005em;
  position: relative;

  :before {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: #b9bab4;
    opacity: ${props => (props.isOpen ? 1 : 0)};
  }
`
export const NavListItem = styled.li`
  position: relative;
  padding: 9px 24px;
  margin: 0;

  ::before {
    position: absolute;
    content: '';
    top: 50%;
    transform: translateY(-50%);
    left: 0px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #a55331;
  }

  a:hover {
    span {
      color: #a55331;
    }
  }
`
