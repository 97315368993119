import React from 'react'
import { GetCatIcon, hasIcon, getCatLink } from '../../utils/NewCategoryicons'
import { getCleanCategories } from '../../utils/helpers'

import { Container, IconWrapper, IconLink } from './style.js'
import RegionTag from '../Teaser/RegionTag'

const Categories = props => {
  if (props.categories && props.categories.length === 0) {
    return null
  }

  const cats = getCleanCategories(props.categories.slice(0, 3)).filter(cat => hasIcon(cat))
  console.log('cats:', cats)

  return (
    <Container hasCat={!!cats?.length}>
      {cats?.map(
        (category, index) =>
          hasIcon(category) && (
            <IconLink to={getCatLink(category)}>
              <IconWrapper key={index}>
                <GetCatIcon category={category} />
                <p dangerouslySetInnerHTML={{ __html: category }} />
              </IconWrapper>
            </IconLink>
          )
      )}

      {props.region && <RegionTag {...props.region} />}
    </Container>
  )
}

export default Categories
