import React, { useState } from 'react'

import { ReactComponent as ArrowRight } from '../../images/arrow-right.svg'

import {
  Header,
  MainMenuTitle,
  LinkArrowContainer,
  Title,
  NavList,
  NavListItem,
  IconWrapper,
} from './style'

import FancyLink from '../../utils/FancyLink'

const InThisSection = ({ ancestors, currentPageLink }) => {
  console.log('ancestors:', ancestors)
  const sectionPages = ancestors
  orderRhsNav(sectionPages)

  // const sectionPages = [
  //   {
  //     title: 'First Section',
  //     uri: '/yoo',
  //     children: {
  //       nodes: [
  //         { title: 'First Page', uri: 'hello' },
  //         { title: 'Second Page', uri: 'hello' },
  //         { title: 'Third Page', uri: 'hello' },
  //       ],
  //     },
  //   },
  //   {
  //     title: 'Second Section',
  //     uri: '/yoo2',
  //     children: {
  //       nodes: [
  //         { title: 'First Page', uri: 'hello' },
  //         { title: 'Second Page', uri: 'hello' },
  //         { title: 'Third Page', uri: 'hello' },
  //       ],
  //     },
  //   },
  // ]
  const { initialNavIndex, secondNavIndex } = getInitialOpenNav(
    sectionPages,
    currentPageLink
  )
  const [expanded, setExpanded] = useState(true)
  const [currentlyExpanded, setCurrentlyExpanded] = useState(initialNavIndex)

  const handleClick = index => {
    if (index === currentlyExpanded) {
      setCurrentlyExpanded(false)
    } else {
      setCurrentlyExpanded(index)
    }
  }

  console.log('sectionPages', sectionPages)

  return (
    <div>
      <Header>MORE RESOURCES</Header>

      {sectionPages?.map((page, index) => {
        if (page.depth !== 0) return null
        return (
          <div key={index} background="#FBF9F6">
            <LinkArrowContainer>
              <FancyLink to={page.uri} activeClassName="active">
                <MainMenuTitle
                  dangerouslySetInnerHTML={{ __html: page.title }}
                  isOpen={index === currentlyExpanded}
                />
              </FancyLink>

              {page?.wpChildren?.nodes?.length > 0 && (
                <IconWrapper
                  onClick={() => handleClick(index)}
                  isOpen={index === currentlyExpanded}
                >
                  <ArrowRight />
                </IconWrapper>
              )}
            </LinkArrowContainer>

            {page?.wpChildren?.nodes?.length > 0 && (
              <NavList expanded={index === currentlyExpanded}>
                {page?.wpChildren.nodes.map((secondary, index) => {
                  return (
                    <NavListItem key={index + `secondary`}>
                      <FancyLink to={secondary.uri} activeClassName="active">
                        <Title>{secondary.title}</Title>
                      </FancyLink>
                    </NavListItem>
                  )
                })}
              </NavList>
            )}
          </div>
        )
      })}
    </div>
  )
}
export default InThisSection

export const orderRhsNav = ancestors => {
  if (!ancestors) {
    return []
  }

  const orderedTop = ancestors?.sort(function(a, b) {
    return a.depth - b.depth
  })

  let sectionPages = orderedTop ? orderedTop[0]?.wpChildren?.nodes : []

  sectionPages?.sort(function(a, b) {
    return a.menuOrder - b.menuOrder
  })

  return sectionPages
}

const getInitialOpenNav = (sectionPages, currentPageLink) => {
  let initialNavIndex = 0
  let secondNavIndex = 0

  sectionPages.forEach((page, index) => {
    if (page.uri === currentPageLink) {
      initialNavIndex = index
    }

    if (page.children && page.children.length > 0) {
      page.children.forEach(page => {
        if (page.uri === currentPageLink) {
          initialNavIndex = index
          secondNavIndex = page.uri
        } else if (page.children && page.children.length > 0) {
          page.children.forEach(page => {
            if (page.uri === currentPageLink) {
              initialNavIndex = index
            }
          })
        }
      })
    }
  })

  return { initialNavIndex, secondNavIndex }
}
